export var SearchList = [
  { labe: '索赔单号', code: 'qualityCode', type: 'input' },
  {
    labe: '索赔类型',
    code: 'claimType',
    type: 'select',
    option: [
      { label: '来料检验索赔', value: '1' },
      { label: '售后索赔', value: '2' },
      { label: '生产过程索赔', value: '3' },
      { label: '行政索赔', value: '4' }
    ]
  },
  { labe: '公司', code: 'company', type: 'input' },
  { labe: '供应商', code: 'supplier', type: 'input' },
  { labe: '物料编码', code: 'materiel', type: 'input' },
  {
    labe: '创建日期',
    code: 'createDate',
    type: 'daterange'
  },
  {
    labe: '要求反馈日期',
    code: 'feedbackDate',
    type: 'daterange'
  },
  {
    labe: '状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '待我确认', value: '1' },
      { label: '待确认索赔', value: '3' },
      { label: '已完成', value: '4' }
    ]
  }
]
export var SearchData = {
  qualityCode: '',
  claimType: '',
  company: '',
  supplier: '',
  materiel: '',
  createStartTime: '',
  createEndTime: '',
  feedbackStartTime: '',
  feedbackEndTime: '',
  status: ''
}
export var tableField = [
  { label: '索赔单号', code: 'qualityCode', type: 'input', width: '100px' },
  {
    label: '索赔类型',
    code: 'claimType',
    type: 'function',
    width: '120',
    handle: (index, data) => {
      return data.claimType === 1 ? '来料检验索赔' : data.claimType === 2 ? '售后索赔' : data.claimType === 3 ? '生产过程索赔' : data.claimType === 4 ? '行政索赔' : ''
    }
  },
  { label: '索赔总额', code: 'claimMoney', type: 'input', width: '110px' },
  { label: '公司名称', code: 'companyName', type: 'input', width: '120px' },
  { label: '质量异常单号', code: 'qualityCode', type: 'input', width: '120px' },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '120',
    handle: (index, data) => {
      return data.status === null ? '待我确认' : data.status === 1 ? '待我确认' : data.status === 2 ? '待采购确认' : data.status === 3 ? '待确认索赔' : data.status === 4 ? '已完成' : ''
    }
  },
  { label: '要求反馈日期', code: 'feedbackDate', type: 'input', width: '110px' },
  { label: '创建日期', code: 'createDate', type: 'input', width: '110px' },
  {
    label: '操作',
    code: 'act',
    width: '60px',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
    ]
  }
]

export var tableData = []
