<template>
<!-- 供应商惩罚及追责列表 -->
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <div class="contractMenu">
        <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
        <el-button @click="exportlist " class="rightMenu" type="text" >导出</el-button>
    </div>
    <global-table :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData } from './js/setting.js'
import { request, exportForm } from '@/assets/js/http.js'

// import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'accountability',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      paginationData: {},
      pageSize: pageSize,
      pageNum: 1,
      menudata: this.$store.state.ViewMenuData.menuData,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.claimList()
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  },
  methods: {
    claimList () {
      var searchdata = { ...this.childData.searchData }
      searchdata.pageNum = this.pageNum
      searchdata.pageSize = this.pageSize
      request('/api/quality/claim/list', 'GET', searchdata).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          if (res.data.total !== this.paginationData.total) {
            this.paginationData = { pageSize: this.pageSize, total: res.data.total }
          }
        }
      })
    },
    // 点击操作里的按钮进行操作
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'NoticeofClaim' }, query: { id: data.id } })
      } else {
        // this.$router.push({ params: { viewName: 'MyBidRating' }, query: { objData: data.id } })
      }
    },
    // 查询
    onSearch (data) {
      this.claimList()
    },
    // 清空
    clearSearch (data) {
      this.claimList()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.claimList()
    },
    exportlist () {
      exportForm('/api/quality/claim/export', 'get').then((res) => {
        const url = window.URL.createObjectURL(res.data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        eleLink.click()
        window.URL.revokeObjectURL(url)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.contractMenu{
 position: relative;
 width: 100%;
 height: 56px;
}
.rightMenu{
    position: absolute;
    right: 30px;
    top: 8px;
    font-size: 16px;
}
</style>
